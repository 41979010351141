import { Observer } from 'mobx-react-lite';

import DocumentsTree from 'page/privileges/app-users/DocumentsTree';
import { SignaturePad } from 'components/form/canvas';
import StateDropdown from 'components/project/dropdown/StateDropdown';
import NpiLookupInput from 'components/project/NpiLookupInput';
import { IconButton } from 'components/button';
import Fieldset from 'components/form/Fieldset';
import Text from 'components/form/input/Text';
import Radio, { TRadioOption } from 'components/form/input/Radio';
import Select from 'components/form/input/Select';
import Custom from 'components/form/input/Custom';
import Checkboxes from 'components/form/input/Checkboxes';
import TextCalendar from 'components/form/input/TextCalendar';
import PasswordGenerator from 'components/form/input/PasswordGenerator';
import Checkbox from 'components/form/input/Checkbox';
import TextArea from 'components/form/input/TextArea';
import FileUpload from 'components/form/input/FileUpload';
import { PureInput } from 'components/form/textField';
import Badge from 'components/badge';
import NumberInput from 'components/form/input/NumberInput';
import PhoneComponent from 'components/project/common/PhoneComponent';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import ClinicianGroupDropdown from 'components/project/dropdown/ClinicianGroupDropdown';
import CorporateDropdown from 'components/project/dropdown/CorporateDropdown';
import { SelectOptionType } from 'components/form/input/Select';
import Collapse from 'components/collapse';
import { Grid, GridControlButton } from 'components/grid';
import ApplicationUsersDropdown from 'components/project/dropdown/ApplicationUsersDropdown';
import SalutationDropdown from 'components/project/dropdown/SalutationDropdown';
import SpecialtyDropdown from 'components/project/dropdown/SpecialtyDropdown';
import TaxonomyCodeDropdown from 'components/project/dropdown/TaxonomyCodeDropdown';
import UserTypeDropdown from 'components/project/dropdown/UserTypeDropdown';
import SuffixDropdown from 'components/project/dropdown/SuffixDropdown';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';

import UserSelectionActions from 'actions/project/UserSelectionActions';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserProfileStore from 'stores/UserProfileStore';
import { NpiType } from 'stores/_mobx/npi';
import { VaccinationDetailType } from 'stores/_mobx/users/vaccination';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import AccessUtils from 'utils/AccessUtils';
import { dateToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';
import {
  PASSWORD_LENGTH_MAX,
  PASSWORD_LENGTH_MIN,
} from 'constant/PasswordParams';
import { URL_DATA_GOV } from 'constant/externalLinks';

const COSIGN_REQUIRED = [
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
];
const PHYSICIAN_STATUS = [
  { value: 'A', label: 'Active' },
  { value: 'I', label: 'Inactive' },
];
const NOTIFY_SUPERVISOR = [
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
];
const PECOS = [
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' },
];
const PHYSICIAN_NOTIFICATION = [
  { value: 'M', label: 'Monthly' },
  { value: 'W', label: 'Weekly' },
  { value: 'D', label: 'Daily' },
  { value: 'H', label: 'Hourly' },
];
export const LICENSE_CERTIFICATION = 'certification';
export const LICENSE_CREDENTIAL = 'credential';

interface PasswordStrength {
  min_character: number;
  alpha_numeric: 'Y' | 'N';
  isRadiologist: boolean;
}

const prepareValidations = ({
  isRadiologist,
  ...passwordStrength
}: PasswordStrength) => {
  const required = !isRadiologist;

  if (passwordStrength) {
    const out: any = {
      required,
      length: [
        passwordStrength.min_character || PASSWORD_LENGTH_MIN,
        PASSWORD_LENGTH_MAX,
      ],
    };
    if (passwordStrength.alpha_numeric === 'Y') {
      out.custom = (value: any) => {
        const containNumbers = /(\d)/.test(value);
        const containLetters = /(\D)/.test(value);
        return containNumbers && containLetters;
      };
    }
    return out;
  }
  return { required };
};

export default class FormComponentsProvider {
  fieldsetsDisabled: boolean = false;
  legendClassName: string = '';
  toggleFieldSet: (name: string, openOnly?: boolean) => void = null;
  isNew: boolean = false;
  state: any = null;
  me: any = null;
  isRadiology: boolean = false;

  constructor(
    fieldsetsDisabled: boolean,
    legendClassName: string,
    toggleFieldSet: (name: string, openOnly?: boolean) => void,
    isNew: boolean,
    state: any,
    me: any,
    isRadiology: boolean
  ) {
    this.isNew = isNew;
    this.fieldsetsDisabled = fieldsetsDisabled;
    this.legendClassName = legendClassName;
    this.toggleFieldSet = toggleFieldSet;
    this.state = state;
    this.me = me;
    this.isRadiology = isRadiology;
    if (
      !UserSelectionStore.isUserTypesLoaded() &&
      !UserSelectionActions.isLoadedWithoutFilter
    ) {
      UserSelectionActions.loadUserTypes();
    }
  }

  getUserTitleFieldset(
    userTypeTogglerOptions: Array<TRadioOption>,
    messagingOptions: Array<TRadioOption>,
    isPhysician: boolean,
    callback: (value: string) => void = null,
    onlyCorporate: boolean = false,
    isTechnologist: boolean = false,
    accessOptions: Array<TRadioOption> = []
  ) {
    const { state } = this;
    const { model } = state;
    let options: Array<TRadioOption>;
    if (!isPhysician && !UserProfileStore.isClientUser()) {
      options = userTypeTogglerOptions;
    } else {
      options = userTypeTogglerOptions[0].checked
        ? [userTypeTogglerOptions[0]]
        : [];
    }

    return (
      <Fieldset className="row">
        {onlyCorporate || !options.length ? null : (
          <Radio
            name="userTypeToggler"
            label="User Type"
            className={`part-inline ${
              this.isNew ? 'col-sm-12 col-lg-6 col-xl-auto' : 'visually-hidden'
            }`}
            options={options}
            onSetValue={(name: string, value: string) => callback(value)}
          />
        )}

        {isPhysician ? null : (
          <ApplicationUsersDropdown
            name="userType"
            label="User Role"
            userType={model.userTypeToggler}
            applyFiltering
            optionsFilter={
              onlyCorporate
                ? (option: { label: string }) => {
                    return option.label.toLowerCase().indexOf('corporate') > -1;
                  }
                : null
            }
            className={
              this.isNew || !model.userType
                ? 'col-md-6 col-xl-4'
                : 'visually-hidden'
            }
          />
        )}
        <Text
          name="userTitle"
          className={isPhysician ? 'visually-hidden' : 'col-md-6 col-xl-4'}
        />
        <Radio
          name="messaging"
          className="messaging part-inline col-md-auto"
          options={messagingOptions}
        />
      </Fieldset>
    );
  }

  getGeneralInformationFieldset(
    genderOptions: Array<TRadioOption>,
    uploadClb: (
      name: string,
      value: any,
      errorMessages: any,
      errorKeys?: any,
      event?: any,
      rowRef?: any
    ) => void,
    deleteClb: () => void,
    isPhysician: boolean
  ) {
    let avatarUrl: string;

    if (this.state.isTempImageUploaded) {
      avatarUrl = `${BASE_URL_FILE_DIR}tempfolder/${this.state.tempMainImage}`;
    } else if (this.state.model.imageUpload === 'user_female.jpg') {
      avatarUrl = '/assets/images/user_female.jpg';
    } else if (
      this.state.model.imageUpload === 'user_male.jpg' ||
      this.state.model.imageUpload === 'default_user.jpg'
    ) {
      avatarUrl = '/assets/images/default_user.jpg';
    } else if (this.state.model.imageUpload) {
      avatarUrl = `${BASE_URL_FILE_DIR}doc_img/${
        isPhysician ? 'providerimage/' : 'staffimage/'
      }${this.state.model.imageUpload}`;
    } else {
      avatarUrl = null;
    }

    return (
      <Collapse
        title="General Information"
        isCollapsed={false}
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <div className="row">
            <Fieldset className="col-sm-4" {...props}>
              <SalutationDropdown name="salutation" />
              <Text name="lastName" validations="required" />
              <Text name="firstName" validations="required" />
              <Text name="middleName" />
              <SuffixDropdown name="qualification" />
              <TextCalendar name="dateOfBirth" />
              <Radio
                name="gender"
                className="inline"
                options={genderOptions}
                validations="required"
              />
              <UserTypeDropdown name="userType" attr={{ disabled: true }} />
              {parseInt(this.state.model.userType) === 2 ? (
                <Text name="phy_group" label="Provider Group" />
              ) : null}
            </Fieldset>
            <div className="col-sm-4">
              <div className="canvas">
                {avatarUrl ? <img src={avatarUrl} alt="avatar" /> : null}
              </div>
              <FileUpload
                name="imageUpload"
                label="Profile Image"
                onDelete={deleteClb}
                onSetValue={uploadClb}
              />
            </div>
          </div>
        )}
      />
    );
  }

  getLoginInformationFieldset(
    onSetPassword: (
      name: string,
      value: any,
      errorMessages: any,
      errorKeys?: any,
      event?: any,
      rowRef?: any
    ) => void,
    repeatPasswordValidations: any,
    repeatPasswordErrorMessages:
      | string
      | { required?: string; length?: string; custom?: string }
      | Array<any>
  ) {
    return (
      <Collapse
        title="Login Information"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Observer>
            {() => {
              const validation = prepareValidations({
                min_character:
                  storePasswordStrength.passwordSettings?.min_character || 0,
                alpha_numeric:
                  storePasswordStrength.passwordSettings?.alpha_numeric || 'N',
                isRadiologist: this.isRadiology,
              });

              return (
                <Fieldset className="row" {...props}>
                  <Text
                    name="username"
                    className="col-md-4"
                    validations={
                      (this.isRadiology ? '' : 'required') + ' length:3:50'
                    }
                  />
                  <div className="w-100" />
                  <PasswordGenerator
                    hideHints
                    name="password"
                    className="col-md-4"
                    isClearable={false}
                    validations={validation}
                    errorMessages={{
                      custom:
                        'Must contain at least one number and one character',
                      length: 'Password too short',
                      required: 'Please enter password',
                    }}
                    onSetValue={onSetPassword}
                  />
                  <PasswordGenerator
                    hideHints
                    name="repeatPassword"
                    className="col-md-4"
                    isClearable={false}
                    errorMessages={repeatPasswordErrorMessages}
                    validations={repeatPasswordValidations}
                  />
                </Fieldset>
              );
            }}
          </Observer>
        )}
      />
    );
  }

  getEmployerDetailsFieldset(
    facilityUser: boolean,
    facilityUserClass: string,
    changeFacility: (name: string, value: any, error: any) => void,
    isClientUser: boolean
  ) {
    const clazz = 'col-sm-4';
    const facility = this.state.facility;
    const isRadiologist = this.isRadiologist();
    const isCorporateMedicalOrBillingCoordinator =
      this.isCorporateMedicalOrBillingCoordinator();
    const showFacility =
      !isRadiologist && !isCorporateMedicalOrBillingCoordinator;
    const title =
      isRadiologist || this.isPhysician()
        ? 'Primary Practice Address'
        : 'Employer Details';

    return (
      <Collapse
        title={title}
        isDisabled={this.fieldsetsDisabled}
        render={(props) => (
          <Fieldset className="row" {...props}>
            {isCorporateMedicalOrBillingCoordinator ? (
              <CorporateDropdown
                name="corporateId"
                className={clazz}
                validations="required"
              />
            ) : null}
            {isRadiologist ? (
              <RadiologyDropdown
                name="radiologyGroups"
                attr={{ isMulti: true }}
                className={facilityUserClass}
              />
            ) : showFacility ? (
              <FacilityRolesDropdown
                name="facility"
                validations={
                  isClientUser && !isCorporateMedicalOrBillingCoordinator
                    ? 'required'
                    : ''
                }
                attr={{ disabled: isCorporateMedicalOrBillingCoordinator }}
                className={facilityUserClass}
                onSetValue={changeFacility}
              />
            ) : null}
            {showFacility ? (
              <Text
                name="state"
                attr={{ disabled: true }}
                value={facility?.state || ''}
                className={facilityUserClass}
              />
            ) : null}
            {showFacility ? (
              <Text
                name="region"
                attr={{ disabled: true }}
                value={facility?.region || ''}
                className={facilityUserClass}
              />
            ) : null}

            {showFacility ? (
              <Text
                name="location"
                attr={{ disabled: true }}
                value={facility?.location || ''}
                className={facilityUserClass}
              />
            ) : null}
            <NumberInput format="###-###-####" name="phone" className={clazz} />
            <Text
              name="city"
              className={clazz}
              validations={isRadiologist ? 'required' : ''}
            />

            <Text
              name="address"
              validations={this.isRadiology ? 'required' : ''}
              className={clazz}
            />

            <NumberInput
              format="#####-####"
              name="zipCode"
              label="Zip Code"
              className={clazz}
              validations={isRadiologist ? 'required' : ''}
            />
            <Text
              name="officeState"
              label="Office State"
              className={clazz}
              validations={isRadiologist ? 'required' : ''}
            />
            <Select
              name="floor"
              options={this.state.floorArray || []}
              onAjax={
                !this.isNew &&
                this.state.floorArray &&
                this.state.floorArray.length === 0
              }
              className={facilityUserClass}
            />
          </Fieldset>
        )}
      />
    );
  }

  getPhysicianEmployerDetailsFieldset() {
    return (
      <Collapse
        title="Primary Practice Address"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <Text name="address" className="col-md-4" />
            <Text name="city" className="col-md-4" />
            <Text
              name="officeState"
              label="Office State"
              className="col-md-4"
            />
            <NumberInput
              format="#####-####"
              name="zipCode"
              label="Zip Code"
              className="col-md-4"
            />
            <NumberInput
              format="###-###-####"
              name="phone"
              className="col-md-4"
            />
          </Fieldset>
        )}
      />
    );
  }

  getLicensorFieldset(updateRadiologistData: (entity: NpiType) => void) {
    return (
      <Collapse
        title="Licensor"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <Text name="dea" label="DEA" className="col-sm-4" />
            <Radio
              name="co_sign_required"
              className="col-sm-4 part-inline"
              options={COSIGN_REQUIRED}
            />
            <Radio
              name="provider_status"
              label="Physician Status"
              className="col-sm-4 part-inline"
              validations="required"
              options={PHYSICIAN_STATUS}
            />

            <Text name="website" className="col-sm-4" />
            <Text
              name="provider_level"
              label="Physician Level"
              className="col-sm-4"
            />
            <Text name="upin" label="UPIN" className="col-sm-4" />

            <Text name="ein_name" label="EIN Name" className="col-sm-4" />
            <Text name="ein" label="EIN" className="col-sm-4" />
            <SpecialtyDropdown
              name="speciality"
              className="col-sm-4"
              validations="required:lazy"
              errorMessages="Select a speciality"
            />

            <Text name="supervisor" className="col-sm-4" />
            <Text name="clia_number" label="CLIA Number" className="col-sm-4" />
            <Radio
              name="notify_supervisor"
              className="col-sm-4 part-inline"
              options={NOTIFY_SUPERVISOR}
            />

            <NpiLookupInput
              name="npi"
              validations="required"
              onLookup={updateRadiologistData}
              label="NPI"
              className="col-sm-4"
            />
            <Text
              name="referring_nos"
              label="Referring Number"
              className="col-sm-4"
            />

            <div className="w-100" />
            <StateDropdown name="state_0" label="State" className="col-sm-4" />
            <Text name="licenseno_0" label="License No" className="col-sm-4" />
            <TaxonomyCodeDropdown
              name="taxonomy_code"
              label="Taxonomy Code"
              validations="required:lazy"
              className="col-sm-4"
            />

            <div className="w-100" />
            <Radio
              name="pecos"
              className="col-sm-auto part-inline"
              options={PECOS}
              validations={
                UserProfileStore.getUserType() === 'L' ||
                UserProfileStore.getUserType() === 'D' ||
                parseInt(this.state.model.userType) === 2 ||
                parseInt(this.state.model.userType) === 11
                  ? 'required'
                  : null
              }
              label={
                <>
                  PECOS
                  <Badge variant="danger" className="mx-2">
                    <a
                      href={URL_DATA_GOV}
                      target="_blank"
                      className="link-light"
                      rel="noreferrer">
                      PECOS
                    </a>
                  </Badge>
                </>
              }
            />
          </Fieldset>
        )}
      />
    );
  }

  getContactsDetailsFieldset(
    onSetPhone: (name: string, value: any, error: any) => void,
    appendTechData: boolean
  ) {
    const title =
      UserProfileStore.getUserType() === 'L' ||
      UserProfileStore.getUserType() === 'D' ||
      parseInt(this.state.model.userType) === 2 ||
      parseInt(this.state.model.userType) === 11
        ? 'Mailing Address'
        : 'Contact Details';

    return (
      <Collapse
        title={title}
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <Text name="contactAddress" className="col-md-4" />
            <Text name="contactCity" className="col-md-4" />
            <Text name="contactState" className="col-md-4" />

            <NumberInput
              format="#####-####"
              name="contactZipCode"
              label="Zip Code"
              className="col-md-4"
              validations="zip"
            />
            <NumberInput
              format="###-###-####"
              name="contactPhone"
              className="col-md-4"
              validations="phone"
            />
            <Custom
              name="mobile"
              className="row col-md-4"
              custom={
                <PhoneComponent
                  onSetValue={onSetPhone}
                  wrapperClass="row"
                  text={{
                    name: 'contactMobile',
                    value: this.state.model.contactMobile,
                    noLabel: true,
                    className: 'col-sm-6',
                    validations: 'phone',
                  }}
                  select={{
                    name: 'contactMobileOperator',
                    value: this.state.model.contactMobileOperator,
                    className: 'col-sm-6',
                    noLabel: true,
                    options: this.state.mobileOperator,
                  }}
                />
              }
            />

            <Text
              name="email"
              validations={
                (this.isRadiology || this.isPhysician() ? '' : 'required') +
                ' email'
              }
              className="col-md-4"
            />
            <NumberInput
              name="fax"
              format="###-###-####"
              validations="phone"
              className="col-md-4"
            />
            <Text name="emergencyName" className="col-md-4" />

            <NumberInput
              name="emergencyPhone"
              format="###-###-####"
              validations="phone"
              className="col-md-4"
            />
            <Text name="spouseName" className="col-md-4" />
            <Text
              name="linkedIdName"
              label="LinkedIn ID"
              className="col-md-4"
            />

            {appendTechData ? (
              <Checkbox name="technologist" className="col-md-2" />
            ) : null}
            {appendTechData ? (
              <Checkbox name="serviceUser" className="col-md-2" />
            ) : null}
            {appendTechData ? (
              <NumberInput
                name="ssn"
                format="###-##-####"
                label="Social Security Number"
                className="col-md-3"
                validations="ssn"
                errorMessages={"Invalid format. Only numbers and '-' allowed."}
              />
            ) : null}
          </Fieldset>
        )}
      />
    );
  }

  handleChangeSignature = (name: string, value: string) => {
    const model = { ...this.me.state.model, [name]: value };
    this.me.setState({ model, isFileChanged: true });
  };

  getRadiologyFieldset(onRadiologistLookup: (entity: NpiType) => void) {
    if (!this.isRadiology) {
      return null;
    }
    return (
      <Collapse
        title="Radiology"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <SpecialtyDropdown
              name="taxonomy_code"
              className="col-sm-4"
              errorMessages="Select a speciality"
            />
            <TaxonomyCodeDropdown
              name="taxonomy_code"
              label="Taxonomy Code"
              validations="required:lazy"
              className="col-sm-6"
            />
            <NpiLookupInput
              name="npi"
              validations="required"
              onLookup={onRadiologistLookup}
              label="NPI"
              className="col-sm-4"
            />
          </Fieldset>
        )}
      />
    );
  }

  getUploadSignatureFieldset(isPhysician: boolean) {
    if (!(isPhysician || this.isRadiology)) {
      return null;
    }
    return (
      <Collapse
        title="Upload Signature"
        isDisabled={this.fieldsetsDisabled}
        render={() => (
          <SignaturePad
            name="signature"
            onSetValue={this.handleChangeSignature}
            value={this.me.state.model.signature}
          />
        )}
      />
    );
  }

  getEmailNotification(
    onChangeEmailNotify: (
      name: string,
      value: string,
      errorMessages: any
    ) => void
  ) {
    const model = this.state.model || {};
    return (
      <Collapse
        title="Email Notification"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <Radio
              className="part-inline col-md-6"
              label="Physician Unsigned Notification"
              name="emailnotify"
              options={PHYSICIAN_NOTIFICATION}
              onSetValue={(n, v, e) => onChangeEmailNotify(n, v, e)}
            />
            {model.emailnotify === 'H' ? (
              <Text
                className="col-md-6"
                label="Notify Each Hour"
                validations="positive"
                onSetValue={(n, v, e) => this.isNumberCheck(n, v, e)}
                name="emailnotify_period"
              />
            ) : null}

            <Text name="notificationEmail" className="col-md-6" />
          </Fieldset>
        )}
      />
    );
  }

  getClinicalGroup(
    clinicalGroupAddClb: (value: Array<string>) => void,
    canEditPhyGroupAccess: boolean,
    physicianAdminForGroup?: number
  ) {
    const isDisabled = physicianAdminForGroup === 0;
    return (
      <Collapse
        title="Clinical Group"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <Select
              attr={{ isMulti: true, disabled: isDisabled }}
              onSetValue={(n: string, v: Array<string>) =>
                clinicalGroupAddClb(v)
              }
              name="clinicalGroup"
              label="Clinical Groups:"
              className="col-md-4"
              options={this.state.clinicalGroupData}
            />
            {canEditPhyGroupAccess ? (
              <Fieldset className="row">
                <Checkbox
                  name="isPhysicianAdmin"
                  label="Is Administrator"
                  className="col-md-4"
                  attr={{ disabled: isDisabled }}
                />
                <ClinicianGroupDropdown
                  name="supervisedGroup"
                  attr={{
                    disabled: !this.state.model.isPhysicianAdmin || isDisabled,
                  }}
                  optionsFilter={(v: any) => {
                    const allowed = this.state.model.clinicalGroup || [];
                    return allowed.includes(v.value);
                  }}
                  className="col-md-4"
                />
              </Fieldset>
            ) : null}
          </Fieldset>
        )}
      />
    );
  }

  getOtherDocuments(userId: number) {
    if (!AccessUtils.checkAccess('user_other_documents')) {
      return null;
    }

    return (
      <Collapse
        title="Other Documents"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="document-tree" {...props}>
            <DocumentsTree noclone="true" userId={userId} />
          </Fieldset>
        )}
      />
    );
  }

  getSupervisedGroup() {
    return (
      <Collapse
        title="Clinical Group"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            <ClinicianGroupDropdown
              name="supervisedGroup"
              label="Clinical group admin"
              validations="required:lazy"
              className="col-md-4"
            />
          </Fieldset>
        )}
      />
    );
  }

  getVaccinationFieldset(
    vaccinations: VaccinationDetailType[],
    handleDeleteVaccination: (id: number) => void,
    handleUpdateVaccine: (idx: number) => (comment: string) => void
  ) {
    return (
      <Collapse
        title="Vaccinations"
        isDisabled={this.fieldsetsDisabled}
        render={() => (
          <Grid
            columns={[
              {
                title: 'Vaccination',
                name: 'vaccinationName',
              },
              {
                title: 'Certificate of vaccination',
                name: 'document',
                render: (document: string | File, data: any) => {
                  const isLinkToFile = typeof document === 'string';

                  const link = isLinkToFile
                    ? document
                    : URL.createObjectURL(
                        new Blob([document], { type: 'application/pdf' })
                      );
                  return (
                    <a href={link} target="_blank" rel="noreferrer">
                      {data.documentName}
                    </a>
                  );
                },
              },
              {
                title: 'Comment',
                name: 'comment',
                render: (
                  comment: string,
                  vaccine: VaccinationDetailType,
                  idx: number
                ) => (
                  <PureInput
                    name={`comment_${idx}`}
                    value={comment}
                    onChange={handleUpdateVaccine(idx)}
                  />
                ),
              },
              {
                title: 'Date of uploading',
                name: 'date',
              },
              {
                title: 'Action',
                name: 'action',
                render: (value: undefined, { vaccinationTypeId }: any) => (
                  <IconButton
                    className="text-danger"
                    onClick={() => handleDeleteVaccination(vaccinationTypeId)}>
                    <i className="bi bi-trash" />
                  </IconButton>
                ),
              },
            ]}
            gridControlPanelBottom={null}
            disablePagination
            dataSource={vaccinations}
            dataSourceCount={vaccinations.length}
            gridControlPanelTop={
              <GridControlButton
                title="Add"
                onClick={this.me.handleToggleDialogVaccination}
              />
            }
          />
        )}
      />
    );
  }

  getCommentsFieldset() {
    return (
      <Collapse
        title="Comments"
        isDisabled={this.fieldsetsDisabled}
        render={(props) => (
          <Fieldset className="row" {...props}>
            <TextArea
              attr={{ rows: 10 }}
              name="comments"
              label="Enter Your Comments"
            />
          </Fieldset>
        )}
      />
    );
  }

  getEmailTemplatesFieldset() {
    const options: Array<SelectOptionType> = [];
    const values: any = {};
    const prefix = 'Results Available (';
    const suffix = ')';
    (this.state.model.emailTemplates || []).forEach((item: any) => {
      let name4Template = item.template_name || '';
      const date = name4Template.match(
        /\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}(:\d{2})?/
      )?.[0];

      if (date) {
        const userTimezoneDate = dateToLocalTimezone({ date });
        name4Template = prefix + userTimezoneDate + suffix;
      }
      options.push({ value: item.appRefID, label: name4Template });
      values[item.appRefID] = item.selected;
    });
    return (
      <Collapse
        title="Subscribe to Email Notifications"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset className="row" {...props}>
            {options.length === 0 ? (
              <div>No available email subscriptions for this user type</div>
            ) : (
              <Checkboxes
                name="emailTemplates"
                noLabel
                value={values}
                options={options}
              />
            )}
          </Fieldset>
        )}
      />
    );
  }

  isCorporateMedicalOrBillingCoordinator() {
    if (!this.state.model) {
      return false;
    }
    const userType = UserSelectionStore.findUserType(this.state.model.userType);
    if (userType) {
      return userType.usertype === 'CA' || userType.usertype === 'CC';
    }
    return false;
  }

  isRadiologist() {
    return (
      parseInt(this.state.model.userType) === 11 ||
      UserProfileStore.getUserType() === 'L' ||
      parseInt(this.state.model.userType) === 23 ||
      parseInt(this.state.model.userType) === 21
    );
  }

  isPhysician() {
    return (
      parseInt(this.state.model.userType) === 2 ||
      UserProfileStore.getUserType() === 'D'
    );
  }

  isNumberCheck(name: string, value: string, errors: any) {
    if (/^\d*$/g.test(value + '')) {
      this.me.onSetValue(name, value, errors);
    }
  }

  getLicensesFieldset() {
    const credentials: any[] = [];
    const certifications: any[] = [];
    let thisLicenses = this.state.model.licenses;
    let hasCredentials = false;
    let hasCertifications = false;
    if (thisLicenses && thisLicenses.length > 0) {
      for (let i = 0; i < thisLicenses.length; i++) {
        if (hasCredentials && hasCertifications) {
          break;
        }
        let object = thisLicenses[i];
        switch (object.type) {
          case LICENSE_CERTIFICATION:
            hasCertifications = true;
            break;
          case LICENSE_CREDENTIAL:
            hasCredentials = true;
            break;
        }
      }
    }
    if (!hasCredentials) {
      this.addNewLicense(LICENSE_CREDENTIAL);
    }
    if (!hasCertifications) {
      this.addNewLicense(LICENSE_CERTIFICATION);
    }
    if (!hasCredentials || !hasCertifications) {
      thisLicenses = this.state.model.licenses;
    }
    for (let i = 0; i < thisLicenses.length; i++) {
      const object = thisLicenses[i];
      if (object.type === LICENSE_CREDENTIAL) {
        credentials.push(this.createLicenseComponent(object, i, false));
      } else if (object.type === LICENSE_CERTIFICATION) {
        certifications.push(this.createLicenseComponent(object, i, true));
      }
    }
    return (
      <Collapse
        title="Licenses"
        isDisabled={this.fieldsetsDisabled}
        render={(props: any) => (
          <Fieldset {...props}>
            <div className="fw-bold">Credentials</div>
            {credentials}
            <div className="fw-bold">Certification</div>
            {certifications}
          </Fieldset>
        )}
      />
    );
  }

  createLicenseComponent(
    object: {
      key: string;
      tempFile: boolean;
      non_expiring: boolean;
      type: any;
      errors: any;
    },
    i: number,
    appendControl: boolean
  ) {
    if (!object.key) {
      object.key = window.performance.now().toString(36);
    }
    const clazz = 'col-sm-4';
    return (
      <Fieldset
        key={object.key}
        className="mb-3"
        formModel={object}
        formErrors={object.errors}
        onSetValue={(n, v, e) => this.updateLicenses(n, v, e, i)}>
        <Fieldset className="row grouped-fields-block">
          <StateDropdown name="state" className={clazz} />
          <TextCalendar
            name="valid_from"
            className={clazz}
            attr={{ id: 'vf_' + object.key + '_' + i }}
          />
          <TextCalendar
            name="valid_to"
            className={clazz}
            attr={{
              id: 'vt_' + object.key + '_' + i,
              disabled: object.non_expiring,
            }}
          />

          <Text
            name="license_no"
            className={clazz}
            attr={{ id: 'l_' + object.key + '_' + i }}
          />
          <FileUpload
            name="file_name"
            className={clazz}
            onDelete
            path={`${BASE_URL_FILE_DIR}${
              object.tempFile
                ? 'tempfolder'
                : 'documents/' + this.state.model.userinfo_id
            }`}
          />
          <Checkbox
            name="non_expiring"
            className={clazz + ' non_expiring_license'}
            attr={{ id: 'ne_' + object.key + '_' + i }}
          />
          {appendControl ? (
            <div className="grouped-fields-controls">
              <IconButton
                onClick={() => {
                  this.addNewLicense(object.type);
                  this.me.forceUpdate();
                }}>
                <i className="bi bi-plus-circle" />
              </IconButton>

              <IconButton
                onClick={() => {
                  (this.me.state.model.licenses || []).splice(i, 1);
                  this.me.forceUpdate();
                }}>
                <i className="bi bi-trash" />
              </IconButton>
            </div>
          ) : null}
        </Fieldset>
      </Fieldset>
    );
  }

  updateLicenses(name: string, value: string, error: any, index: number) {
    const object = { ...this.state.model.licenses[index] };
    object[name] = value;
    if (!object.errors) {
      object.errors = {};
    }
    if (name === 'file_name') {
      object.tempFile = true;
    } else if (name === 'non_expiring') {
      object.valid_to = '';
    }
    object.errors[name] = error;
    const licenses = [].concat(this.state.model.licenses);
    licenses[index] = object;
    const model = { ...this.state.model, licenses };
    this.me.setState({ model: model });
  }

  addNewLicense(type: any) {
    if (!this.me.state.model.licenses) {
      this.me.state.model.licenses = [];
    }
    this.me.state.model.licenses.push({
      key: window.performance.now().toString(36),
      type: type,
    });
  }
}
