// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import UserProfileStore from 'stores/UserProfileStore';
import { URL_ISSUE_COLLECTOR } from 'constant/externalLinks';

let handleShowCollectorDialog = null;

window['ATL_JQ_PAGE_PROPS'] = {
  triggerFunction: function (showCollectorDialog) {
    handleShowCollectorDialog = showCollectorDialog;
  },
};

interface PropsType {
  className?: string;
}

const JIRAIssueCollector = ({ className }: PropsType) => {
  const initStatus = useRef(null);

  const [isSuperAdmin] = useState<boolean>(UserProfileStore.isSuperAdmin());
  const isCompanyAdmin = Number(UserProfileStore.getUserTypeId()) === 30;

  const isUserHasAccessToContactSupport = isSuperAdmin || isCompanyAdmin;

  const handleClick = (e) => {
    e.preventDefault();

    if (handleShowCollectorDialog) {
      handleShowCollectorDialog();
    }
  };

  const setCollector = useCallback(() => {
    if (handleShowCollectorDialog) return;

    const appElement = document.querySelector('body');

    if (appElement) {
      const snippet = document.createElement('script');

      snippet.type = 'text/javascript';

      snippet.src = URL_ISSUE_COLLECTOR;

      appElement.appendChild(snippet);

      initStatus.current = true;
    }
  }, []);

  useEffect(() => {
    if (!initStatus.current && isUserHasAccessToContactSupport) setCollector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserHasAccessToContactSupport]);

  return (
    isUserHasAccessToContactSupport && (
      <Link className={className} to="/" onClick={handleClick}>
        Contact Support
      </Link>
    )
  );
};

export default JIRAIssueCollector;
